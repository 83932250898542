<template>
    <div
        @click="handleClick"
        class="hm-button"
        :class="`${type}-button`"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "hm-button",
    props: {
        type: {
            type: String
        },
    },
    data() {
        return {};
    },
    methods: {
        handleClick() {
            this.$emit('click')
        }
    },
};
</script>

<style scoped lang="scss">
.hm-button {
    display: inline-block;
    cursor: pointer;
    font-size: 12px;
    height: 26px;
    border-radius: 4px;
    padding: 3px 13px;
    box-sizing: border-box;
    white-space: nowrap;
    +.hm-button {
        margin-left: 10px;
    }
}

.primary-button {
    background-color: #2166ee;
    border: 1px solid #2166ee;
    color: #ffffff;
}

.newDefault-button{
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    &:hover {
        border-color: #2166ee;
        color: #2166ee;
    }
}
.active-button{
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2166ee;
}
.activeMessage-button{
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    color: #2166ee;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    position: relative;
    // &::after{
    //     // var(--number)
    //     content: '5';
    //     display: inline-flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 18px;
    //     height: 18px;
    //     background: #F84141;
    //     border-radius: 9px;
    //     position: absolute;
    //     top: -50%;
    //     transform: translateY(40%);
    //     font-size: 12px;
    //     font-family: Microsoft YaHei;
    //     font-weight: bold;
    //     color: #FFFFFF;
    // }
}
.default-button {
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    color: #666666;

    &:hover {
        border-color: #2166ee;
        color: #2166ee;
    }
}
</style>
