<template>
    <div class="hm-card" :style="{
        height: `${height}px`
    }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "hm-card",
    props: {
        height: {
            type: Number,
        }
    }
};
</script>

<style scoped lang="scss">
.hm-card {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 16px;
}
</style>
